import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import logo from '../../images/tempo-talk-logo.svg';
import Illustration from '../../images/Illustration.svg';
import sdtrw from '../../images/sdtrw.svg';
import resetpass from '../../images/resetpass.svg';
import rcomp from '../../images/rcomp.svg';
import { login,loadUser } from '../../actions/auth';

// Configure AWS SDK with credentials
AWS.config.update({
  region: 'us-east-1', // Replace 'your-region' with your AWS region
  accessKeyId: 'AKIATDRUZBSJZLNZIEG5', // Replace 'your-access-key-id' with your AWS Access Key ID
  secretAccessKey: 'ny53LOaEVQZa9CMX9yt+WaevnYpQpX7Q6yb4amxo', // Replace 'your-secret-access-key' with your AWS Secret Access Key
});

const cognito = new AWS.CognitoIdentityServiceProvider();

function SignInForm() {
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [signedIn, setSignedIn] = useState(false);
  const [sessionToken, setSessionToken] = useState('');
  const [registrationComplete, setRegistrationComplete] = useState(false); 
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
 
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const result = await cognito.adminInitiateAuth({
        AuthFlow: 'ADMIN_NO_SRP_AUTH',
        ClientId: '7svs6a20bele5ne64aqt403vq7', // Replace 'your-app-client-id' with your App Client ID
        UserPoolId: 'us-east-1_xaM1kcMkF', // Replace 'your-user-pool-id' with your User Pool ID
        AuthParameters: {
          USERNAME: username,
          PASSWORD: oldPassword,
        },
      }).promise();

      if (result.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        setSessionToken(result.Session); // Save the session token
        setErrorMessage('');
        setSignedIn(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== retypePassword) {
      setErrorMessage('New password and retype password do not match.');
      return;
    }
    try {
      await cognito.respondToAuthChallenge({
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ClientId: '7svs6a20bele5ne64aqt403vq7', // Replace 'your-app-client-id' with your App Client ID
        ChallengeResponses: {
          NEW_PASSWORD: newPassword,
          USERNAME: username,
        },
        Session: sessionToken // Use the session token obtained from the previous step
      }).promise();

       // Email Verification Step: Mark email as verified
       await cognito.adminUpdateUserAttributes({
        UserPoolId: 'us-east-1_xaM1kcMkF', // Replace 'your-user-pool-id' with your User Pool ID
        Username: username,
        UserAttributes: [
          {
            Name: 'email_verified',
            Value: 'true',
          },
        ],
      }).promise();

      setErrorMessage('');
      setSignedIn(true);
      setRegistrationComplete(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  const continueToDashboard = async () => {
    try {
      console.log(username+newPassword);
      await dispatch(login(username, newPassword)); 
      console.log('Login successful. Redirecting...');
      setRedirect(true);
    } catch (error) {
      console.error('Error signing in:', error);
      console.log('Login failed.', 'danger'); 
      setErrorMessage('An error occurred while signing in.');
    }
  };
  if (redirect) {
    
    return <Navigate to="/dashboard" />;
  }
  return (
    <div className="container-fluid">
      {!signedIn ? (

<div className="row">
      <div className="col-md-6 loginleft">
        <div className='m-l-140 m-t-10'>
        <div><img  src={logo} alt="logo" /></div>
        <div><img src={sdtrw} alt="sdtrw" /></div>
        </div>
        <div className="custom-contentLeft">
        
        <img  src={Illustration} alt="Illustration" />
        </div>
      </div>
      <div className="col-md-6">
      <div className="custom-contentRight">
      <p className="m-l-10"><b>Sign In</b></p>
        <form className="form-horizontal" onSubmit={handleSignIn}>
        
          {errorMessage && <p>{errorMessage}</p>}
          <div className="form-group mb-3">
          <div className="col-12">
          <label className="form-label">Email</label>
            <input
            className='form-control'
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
         
          </div>
          </div>
          <div className="form-group mb-3">
          <div className="col-12">
          <label className="form-label"> Password</label>
            <input
            className='form-control'
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          
          </div>
          </div>
          
          <div className="form-group text-center m-t-40">
        <div className="col-12">
        <input type="submit" className="btn btn-primary w-100 waves-effect waves-light" value="Sign in" />
        <div className='nhelp m-t-10'>Need help? Contact Us! <Link to="/">Get Help</Link> </div>
        </div>
        </div>
        </form>
        </div>
        </div>
        </div>
      ) : (
        registrationComplete ? (
          <div>
            

            <div className="row">
      <div className="col-md-6 loginleft">
        <div className='m-l-140 m-t-10'>
        <div><img  src={logo} alt="logo" /></div>
        <div><img src={sdtrw} alt="sdtrw" /></div>
        </div>
        <div className="custom-contentLeft">
        
        <img  src={rcomp} alt="rcomp" />
        </div>
      </div>
      <div className="col-md-6">
      <div className="custom-contentRight">
      <h4><b>Registration Complete</b></h4>
      <div className="form-group text-center m-t-40">
        <div className="col-12">
        <button className="btn btn-primary w-100 waves-effect waves-light" onClick={continueToDashboard}>Continue to Dashboard</button>
        <div className='nhelp m-t-10'>Need help? Contact Us! <Link to="/">Get Help</Link> </div>
        </div>
        </div>
        </div>
        </div>
        </div>



          </div>
        ) : (
        <div className="row">
      <div className="col-md-6 loginleft">
        <div className='m-l-140 m-t-10'>
        <div><img  src={logo} alt="logo" /></div>
        <div><img src={sdtrw} alt="sdtrw" /></div>
        </div>
        <div className="custom-contentLeft">
        
        <img  src={resetpass} alt="Illustration" />
        </div>
      </div>
      <div className="col-md-6">
      <div className="custom-contentRight" style={{paddingTop:'85px'}}>
      
      <h4><b>First Time Sign In</b></h4>
      <small style={{fontSize:'12px',color:'#ccc'}}>Please chnage your password before continuing</small>
        <form onSubmit={handleChangePassword}>
          
          {errorMessage && <p>{errorMessage}</p>}

          <label className="form-label" style={{fontSize:'12px',fontWeight:'bold'}}>Temporary Password</label>
            <input style={{marginBottom:'20px'}} className='form-control' type="password"  placeholder='input your password here' />

          <label className="form-label" style={{fontSize:'12px',fontWeight:'bold'}}>New Password</label>
            <input style={{marginBottom:'20px'}} className='form-control'  type="password"   value={newPassword}  onChange={(e) => setNewPassword(e.target.value)}   placeholder='input your password here'  />
          
        
          <label className="form-label" style={{fontSize:'12px',fontWeight:'bold'}}>Retype Password</label>
            <input style={{marginBottom:'20px'}} className='form-control' type="password"  value={retypePassword}  onChange={(e) => setRetypePassword(e.target.value)}  placeholder='input your password here'   />
          
          
          <div className="form-group text-center m-t-40">
        <div className="col-12">
        <input type="submit" className="btn btn-primary w-100 waves-effect waves-light" value="Continue" />
        <div className='nhelp m-t-10'>Need help? Contact Us! <Link to="/">Get Help</Link> </div>
        </div>
        </div>
        </form>
        </div>
        </div>
        </div>
        )
      )}
    </div>
  );
}

export default SignInForm;
